<template>
  <v-dialog
    overlay-color="white"
    overlay-opacity="0.87"
    v-model="dialog"
    max-width="600"
  >
    <template #activator="{ on, attrs }">
      <slot name="activator" :on="on" :attrs="attrs" />
    </template>
    <v-card
      ref="signup"
      data-c2a="dates"
      flat
      tile
      width="600"
      class="px-4 py-8"
    >
      <v-btn absolute top right icon tile id="close" @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-title>{{ $t("c2a.signup.title", { company }) }}</v-card-title>
      <v-card-subtitle>
        <i18n path="c2a.signup.subtitle">
          <template #company>
            {{ company }}
          </template>
          <template #free>
            <router-link
              :to="{ name: 'SignUp' }"
              class="text-uppercase accent--text"
              >{{ $t("free") }}</router-link
            >
          </template>
        </i18n>
      </v-card-subtitle>

      <v-card-text>
        {{ $t("c2a.signup.content") }}
        <ul>
          <li v-for="(text, i) in $t('c2a.signup.features')" :key="i">
            {{ text }}
          </li>
        </ul>
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="text-none"
          large
          depressed
          color="accent"
          :to="{ name: 'SignUp' }"
        >
          {{ $t("sign up") }}
        </v-btn>

        <v-btn outlined class="text-none" large text :to="{ name: 'SignIn' }">
          {{ $t("sign in") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Signup",
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapGetters({
      company: "config/company",
    }),
  },
};
</script>
