<template>
  <v-text-field
    append-icon="mdi-keyboard-return"
    autofocus
    color="grey darken-2"
    hide-details
    :placeholder="$t('untitled schedule')"
    prepend-inner-icon="mdi-rename-box"
    solo
    v-model="t"
    @keydown.esc="$emit('close')"
    @keydown.enter="save"
    @focus="(ev) => ev.target.select()"
  >
    <template #prepend>
      <v-btn id="cancel" ref="return" icon small @click="$emit('close')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <span
        class="pl-4 pr-8 text-h6 font-weight-regular"
        v-text="$t('rename schedule')"
      />
    </template>
    <template #append>
      <v-btn id="save" ref="save" icon small color="primary" @click="save">
        <v-icon>mdi-keyboard-return</v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ScheduleRename",
  computed: {
    ...mapGetters({
      title: "draft/title",
    }),
  },
  data: () => ({
    t: "",
  }),
  watch: {
    title: {
      handler(t) {
        this.t = t ?? "";
      },
      immediate: true,
    },
  },
  methods: {
    save() {
      if (this.t === this.title) {
        this.$emit("close");
        return;
      }
      this.$emit("change", this.t);
    },
  },
};
</script>
