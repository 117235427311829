<template>
  <Layout :title="title">
    <template #title>
      <ScheduleRename
        v-if="isRenaming"
        @close="isRenaming = false"
        @change="setTitle"
        data-draft-action-form="rename"
      />

      <ScheduleLabel
        v-show="!isEditing"
        :title="title"
        :data-draft-title="title"
      />
    </template>

    <template #navigation>
      <ViewToggle v-show="!isEditing" />
    </template>

    <template #actions>
      <!-- rename schedule -->
      <v-btn
        ref="rename"
        v-show="!isEditing"
        depressed
        tile
        width="64"
        height="92"
        class="grey--text text--darken-2"
        @click="isRenaming = true"
        data-draft-action-button="rename"
      >
        <div style="height: 56px">
          <v-icon>mdi-rename-box</v-icon>
          <div style="line-height: normal" class="pt-1 text-none text-caption">
            {{ $t("rename") }}
          </div>
        </div>
      </v-btn>

      <!-- set dates -->
      <Signup>
        <template #activator="{ attrs, on }">
          <v-btn
            ref="setDates"
            v-show="!isEditing"
            depressed
            tile
            class="accent--text text--darken-1"
            width="64"
            height="92"
            v-on="on"
            v-bind="attrs"
            data-draft-action-button="dates"
          >
            <div style="height: 56px">
              <v-icon>mdi-calendar-range</v-icon>
              <div
                style="line-height: normal"
                class="pt-1 text-none text-caption"
              >
                {{ $t("set dates") }}
              </div>
            </div>
          </v-btn>
        </template>
      </Signup>
    </template>

    <keep-alive>
      <router-view
        ref="view"
        @updateSeries="updateClassSeries"
        @deleteSeries="deleteClassSeries"
      />
    </keep-alive>
  </Layout>
</template>

<script>
import _ from "lodash";
import { nanoid } from "nanoid";
import { mapGetters } from "vuex";
import Layout from "@/components/draft/Layout";
import ScheduleLabel from "@/components/draft/ScheduleLabel";
import ScheduleRename from "@/components/draft/ScheduleRename";
import Signup from "@/components/draft/Signup";
import ViewToggle from "@/components/draft/ViewToggle";
export default {
  name: "Draft",
  components: {
    Layout,
    ScheduleLabel,
    ScheduleRename,
    Signup,
    ViewToggle,
  },
  props: {
    draft: { type: Object, required: true },
  },
  data: () => ({
    isRenaming: false,
  }),
  computed: {
    ...mapGetters({
      title: "draft/title",
      template: "draft/template",
    }),
    isEditing() {
      return this.isRenaming;
    },
  },
  methods: {
    setTitle(v) {
      this.$store.dispatch("draft/setTitle", v);
      window.localStorage?.setItem("draft/title", v);
      this.isRenaming = false;
    },
    async updateClassSeries(payload, id) {
      await this.$store.dispatch("draft/updateEvent", {
        id: id ?? nanoid(12),
        payload,
      });
      window.localStorage?.setItem(
        "draft/template",
        JSON.stringify(_.cloneDeep(this.template))
      );
    },
    async deleteClassSeries(id) {
      await this.$store.dispatch("draft/deleteEvent", id);
      if (Object.keys(this.template).length)
        window.localStorage?.setItem(
          "draft/template",
          JSON.stringify(_.cloneDeep(this.template))
        );
      else window.localStorage?.removeItem("draft/template");
    },
  },
  created() {
    this.$store.dispatch("draft/setData", this.draft);
  },
};
</script>
