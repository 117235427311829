<template>
  <v-list-item-content>
    <div
      class="text-caption grey--text text--darken-2"
      style="font-family: 'Cantata One', serif !important; margin-bottom: -4px"
      v-text="$t('schedule name')"
    />
    <v-list-item-title
      id="title"
      ref="title"
      class="text-h6 text-truncate"
      style="max-width: 240px; font-family: 'Cantata One', serif !important"
      v-text="title || $t('untitled schedule')"
    />
    <v-list-item-subtitle
      id="dates"
      ref="subtitle"
      class="text-caption grey--text text--darken-1"
    >
      <template v-if="range">
        {{ getRangeLabel(range) }}
      </template>
      <i v-else v-text="$t('unscheduled')" />
    </v-list-item-subtitle>
  </v-list-item-content>
</template>

<script>
import BaseDates from "./BaseDates";
export default {
  name: "ScheduleLabel",
  extends: BaseDates,
  props: {
    title: { type: String },
    range: { type: Object },
  },
};
</script>
