<template>
  <v-main style="padding-top: 64px">
    <!--draft info bar-->
    <v-app-bar absolute outlined flat height="96" style="z-index: 3">
      <v-toolbar-title>
        <slot name="title" />
      </v-toolbar-title>
      <v-spacer />
      <slot name="navigation" />
      <v-spacer />
      <v-toolbar-items>
        <slot name="actions" />
      </v-toolbar-items>
    </v-app-bar>

    <!--content-->
    <v-card class="overflow-hidden" flat tile style="padding-top: 96px">
      <slot></slot>
    </v-card>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Layout",
  computed: {
    ...mapGetters({
      title: "draft/title",
      company: "config/company",
    }),
  },
  methods: {
    setPageTitle(title) {
      document.title = `${title ?? this.$t("untitled schedule")} - ${
        this.company
      }`;
    },
  },
  watch: {
    title(v) {
      this.setPageTitle(v);
    },
  },
  mounted() {
    this.setPageTitle(this.title);
  },
};
</script>
