<template>
  <div>
    <span class="text-h6 pr-4 grey--text text--darken-2" v-text="$t('view')" />
    <v-btn-toggle
      ref="nav"
      :value="$route.meta.view"
      active-class="primary lighten-2"
    >
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            key="times"
            to="times"
            v-bind="attrs"
            v-on="on"
            data-goto="times"
          >
            <v-icon>mdi-clock</v-icon>
          </v-btn>
        </template>
        <span v-text="$t('manage class times')" />
      </v-tooltip>

      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn
            key="staff"
            to="staff"
            v-bind="attrs"
            v-on="on"
            data-goto="staff"
          >
            <v-icon>mdi-account-supervisor-circle</v-icon>
          </v-btn>
        </template>
        <span v-text="$t('assign staff')" />
      </v-tooltip>
    </v-btn-toggle>
  </div>
</template>

<script>
export default {
  name: "ViewToggle",
};
</script>
