import moment from "moment";

export default {
  methods: {
    getRangeLabel(value) {
      const beg = moment(value.beg);
      const end = moment(value.end);

      // compare years
      if (beg.year() !== end.year()) {
        const from = beg.format("MMM D, YYYY");
        const to = end.format("MMM D, YYYY");
        return `${from}-${to}`;
      }

      // compare months
      if (beg.month() !== end.month()) {
        const from = beg.format("MMM D");
        const to = end.format("MMM D, YYYY");
        return `${from}-${to}`;
      }

      const from = beg.format("MMMM D");
      const to = end.format("D, YYYY");
      return `${from}-${to}`;
    },
  },
};
